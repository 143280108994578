import { render, staticRenderFns } from "./UserWhiteListAddress.vue?vue&type=template&id=2644c70e&scoped=true"
import script from "./UserWhiteListAddress.vue?vue&type=script&lang=js"
export * from "./UserWhiteListAddress.vue?vue&type=script&lang=js"
import style0 from "./UserWhiteListAddress.vue?vue&type=style&index=0&id=2644c70e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2644c70e",
  null
  
)

export default component.exports